import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/33cbcb6e/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "neoanalytics-concept"
    }}>{`NeoAnalytics Concept`}</h1>
    <p>{`NeoAnalytics is a privacy respecting service providing a seemless experience to website visitors without annoying cookie banners, while still providing website operators with insights to optimize their web presence.`}</p>
    <p>{`To achieve this we build an analytics solution with privacy as the number one concern. Never storing any personal information on our systems and never storing anything in the clients browser, neither cookies nor information in other storage solution.`}</p>
    <h2 {...{
      "id": "pageview-script"
    }}>{`PageView script`}</h2>
    <p>{`To integrate NeoAnalytics into your website all you need to do is add the below script.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script async defer src="https://app.neoanalytics.de/neoClient.js"></script>
`}</code></pre>
    <p>{`Now our script will make sure to send a request to our servers for each visit to your page and each navigation on Single Page Applications (React, Angular, Vue, etc.).`}</p>
    <p>{`A request send contains a payload like the one below.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "hostname": "hendrikwallbaum.de",
  "path": "/",
  "width": 1280,
  "referrer": ""
}
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`hostname`}</strong>{` taken from `}<inlineCode parentName="li">{`window.location.hostname`}</inlineCode>{` - the page this page view is recorded for.`}</li>
      <li parentName="ul"><strong parentName="li">{`path`}</strong>{` taken from `}<inlineCode parentName="li">{`window.location.pathname`}</inlineCode>{` - the path on a page a user is currently visiting.`}</li>
      <li parentName="ul"><strong parentName="li">{`width`}</strong>{` the devices width from `}<inlineCode parentName="li">{`window.innerWidth`}</inlineCode>{` - later used to distinguish visiting device types.`}</li>
      <li parentName="ul"><strong parentName="li">{`referrer`}</strong>{` the site you came from, stripped down to it's hostname to protect personal information.`}</li>
    </ul>
    <h3 {...{
      "id": "referrer"
    }}>{`Referrer`}</h3>
    <p>{`Referrer is gathered from one of two sources:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Document.referrer`}</strong>{`, this is set when navigating between pages. We strip this down to the hostname, to defend personal data.`}</li>
      <li parentName="ul"><strong parentName="li">{`Query parameters`}</strong>{`, we also look at query parameters, namely: `}<inlineCode parentName="li">{`ref`}</inlineCode>{` and `}<inlineCode parentName="li">{`utm_source`}</inlineCode>{`. For these we take the entire string provided. Query parameters overrule referrers.`}</li>
    </ul>
    <p>{`Query Parameters overrule referrers. With `}<inlineCode parentName="p">{`ref`}</inlineCode>{` being the most important.`}</p>
    <h2 {...{
      "id": "event-enhancement"
    }}>{`Event enhancement`}</h2>
    <p>{`Server side each page view event gets enhanced with more context. The final element stored looks like such:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "hostname": "hendrikwallbaum.de",
  "path": "/",
  "width": 1280,
  "referrer": "",
  // From here added on server.
  "id": 1,
  "browser": "Chrome | Firefox | Safari",
  "os": "Windows | Android | iOS | macOs | Linux",
  "created_at": 1235456867,
  "visitorHash": "3de3f6c2a19aef2ef012fc44a8842e3bc8d099d1c2a2bedfefc1d08a181a7c96",
  "isOpeningSession": true
}
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`id`}</strong>{` a database specific id.`}</li>
      <li parentName="ul"><strong parentName="li">{`browser`}</strong>{` parsed from UserAgent sent by the browser this stores the browsers name without any additional information. We store "Chrome" and not "Chrome 83".`}</li>
      <li parentName="ul"><strong parentName="li">{`os`}</strong>{` the Operating system, also parsed from UserAgent and not more specific than name.`}</li>
      <li parentName="ul"><strong parentName="li">{`created_at`}</strong>{` timestamp to analyse events over time.`}</li>
      <li parentName="ul"><strong parentName="li">{`visitorHash`}</strong>{` a one-way-hash to identify unique users.`}</li>
      <li parentName="ul"><strong parentName="li">{`isOpeningSession`}</strong>{` whether this pageview marks the start of a new session.`}</li>
    </ul>
    <h3 {...{
      "id": "visitorhash"
    }}>{`visitorHash`}</h3>
    <p>{`The visitorHash is calculated by one-way-hashing the hostname, userAgent and IP of a user using SHA256.`}</p>
    <p>{`The visitorHash is a unique identifier for a user who visits a specific site with a non-updated device over the same IP.`}</p>
    <p>{`For the same user on the same, non-updating device it should change at least once a day, when their ISP provide them a new IP.`}</p>
    <p>{`For users on mobile phones using their mobile network instead of WiFi you might end up with multiple devices having the same IP, which we catch by using the UserAgent here as well. This scenario might also find devices with often changing IPs, in which case we will consider them new, unique visits.`}</p>
    <p>{`The visitorHash is different for the same user visiting multiple NeoAnalytics using sites on the same device over the same network, since we include the hostname in the hash. NeoAnalytics does not posses enough data about users to track them across sites.`}</p>
    <p>{`In the unlikely event of either the visiting clients IP or UserAgent not being present uniqueness of a visit is inferred by comparing referrer and hostname. Should they be unequal a random string is added to the information being hashed to create a unique visitor preserve the count of unique visitors as best, as possible.`}</p>
    <h3 {...{
      "id": "sessions"
    }}>{`Sessions`}</h3>
    <p>{`Some pageviews are opening new Sessions. As a Sessions we see any visit of a user to our page where the referrer differs from the hostname.`}</p>
    <p>{`For SPAs our client-side library ensures to always set the referrer equal to the hostname for pageviews of consecutive navigations on the page.`}</p>
    <p>{`This is prone to creating a few more sessions than what could be detected using cookies and other mechanisms but we take this trade off to product visitors privacy.`}</p>
    <h2 {...{
      "id": "insights"
    }}>{`Insights`}</h2>
    <p>{`This section describes a typical dashboard.`}</p>
    <p>{`At the top we find the domain we are currently viewing insights for, as well as the accumulated count of unique visitors over the last month (based on visitorHash). The graph below shows both unique visitors and page views per day. Having both these values enables customers to gain insights no only on how many visitors they are having but also into how active these visitors are on their page. What is not shown in the early prototype below is a third graph indicating sessions opened on a given day.`}</p>
    <p>{`Below the graph we find more detailed insights.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Sources (Quellen)`}</strong>{` lists all referrers over the path month together with how often someone came to your page from this source.`}</li>
      <li parentName="ul"><strong parentName="li">{`Paths (Pfade)`}</strong>{` lists the absolute amount of time that a specific path was visited. Please note that we append a trailing "/" to each path to prevent different entries for the same path. (as shown above)`}</li>
      <li parentName="ul"><strong parentName="li">{`Browser`}</strong>{` Lists the absolute number of visitors using specific browsers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Devices (Geräte)`}</strong>{` lists devices used to browse a page as a percentage of the total number of unique visits. Based on the width of devices.`}</li>
      <li parentName="ul"><strong parentName="li">{`Operating Systems`}</strong>{` lists the absolute number of used operating systems.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      